import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/Layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`About Infinite Caesura`}</h1>
    <h2>{`Basic context`}</h2>
    <p><strong parentName="p">{`I'm Devin Halladay, a designer working with signs and systems, focused on creating better tools for creativity, communication, and knowledge.`}</strong>{` I am currently a senior product designer at `}<a parentName="p" {...{
        "href": "https://hydraulics.nyc"
      }}>{`Manhattan Hydraulics`}</a>{`, where we're building an embedded product development and R&D team. At Hydraulics, I also work as a generalist across teams at `}<a parentName="p" {...{
        "href": "https://www.garden3d.net"
      }}>{`garden3d`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.sanctuary.computer"
      }}>{`Sanctuary Computer`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://www.xxix.co"
      }}>{`XXIX`}</a>{`.`}</p>
    <p>{`I graduated from `}<a parentName="p" {...{
        "href": "https://www.mica.edu/"
      }}>{`The Maryland Institute College of Art`}</a>{` in 2019 with a BFA in Graphic Design and Critical Theory. I have also worked as a designer for `}<a parentName="p" {...{
        "href": "https://www.palantir.com/"
      }}>{`Palantir Technologies`}</a>{`, `}<a parentName="p" {...{
        "href": "https://roamresearch.com/"
      }}>{`Roam Research`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.quora.com/"
      }}>{`Quora`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.64robots.com/"
      }}>{`64 Robots`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://friendsoftheweb.com/"
      }}>{`Friends of the Web`}</a>{`.`}</p>
    <h2>{`About this site`}</h2>
    <p>{`In the past, this website served as something of a personal blog. As time went on, I found myself writing more passive notes and drawing relationships between source material, than actually penning essays. I wanted a place to store all those thoughts without the pressure of publishing a typical essay, so I created this wiki. On this site, you'll find essays, working notes, creative works, and other types of media, all linked together into a network. You can view the source code `}<a parentName="p" {...{
        "href": "https://github.com/devinhalladay/oikos-garden"
      }}>{`here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      